import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Layout } from '../../Layout';

const Agradecimientos = () => {
  return (
    <Layout title="Agradecimientos">
      <Container>
        <TextStyled>
          Alberto Serrano, Camila Garrido, Alfredo Méndez, Fernando Silva,
          Christine Gleissner, Héctor Pacheco, Alejandra Echeverría, Felipe
          Escalona, Mariela Espinoza, Emilia Larrea, Jaime Lagos.
        </TextStyled>

        <ImageContainer>
          <StaticImage
            src="../../images/agradecimientos/Logo Covadonga Ona.jpg"
            alt="imagen"
            placeholder="blurred"
          />

          <StaticImage
            src="../../images/agradecimientos/Logos portal_Museo Antropológico Martin Gusinde.png"
            alt="imagen"
            placeholder="blurred"
          />

          <StaticImage
            src="../../images/agradecimientos/LOGO_hachsaye_negro.png"
            alt="imagen"
            placeholder="blurred"
          />

          <StaticImage
            src="../../images/agradecimientos/Logo Pëtru-1.png"
            alt="imagen"
            placeholder="blurred"
          />

          <StaticImage
            src="../../images/agradecimientos/TRES-silhueta.png"
            alt="imagen"
            placeholder="blurred"
            className="black"
          />

          <StaticImage
            src="../../images/agradecimientos/cielos.png"
            alt="imagen"
            placeholder="blurred"
            className="black"
          />
        </ImageContainer>
      </Container>
    </Layout>
  );
};

export default Agradecimientos;

const TextStyled = styled.p`
  text-align: center;
  max-width: 400px;
  margin: 100px auto 0 auto;
`;

const ImageContainer = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  place-items: center;
  grid-gap: 50px;
  & > .gatsby-image-wrapper {
    max-width: 200px;
  }

  & > .black {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
`;

const Container = styled.div`
  width: 100%;
`;
